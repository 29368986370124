@import 'variables';
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.services-item {
    /* border: 1px solid $mainColor; */
}
.services-item h5 {
    font-size: 1rem;
    /* color: black; */
}

.arrow-icon {
    color: black;
}

.list-body {
    text-align: left;
}

.list-body p {
    font-size: 1rem;
}

.list-body p FontAwesomeIcon {
    margin-left: 4px;
}
